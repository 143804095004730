import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Layout,
  Header,
  PageTitle,
  PageSubTitle,
  Main,
  Footer,
  mq,
} from "../components/Layout";
import { Container } from "../components/Container";
import { Section, Title } from "../components/Section";
import statuten2020Pdf from "../documents/2020/statuten-2020.pdf";
import beleidsPlan2021Pdf from "../documents/2021/beleidsplan-stichting-mijngilde-2021.pdf";
import publicatieStukken2021Pdf from "../documents/2021/stichting-mijngilde-publicatie-stukken-2021.pdf";
import publicatieStukken2022Pdf from "../documents/2022/stichting-mijngilde-publicatie-stukken-2022.pdf";
import publicatieStukken2023Pdf from "../documents/2023/stichting-mijngilde-publicatie-stukken-2023.pdf";

const OrgPage = () => {
  return (
    <>
      <Helmet>
        <title>Organisatie</title>
      </Helmet>

      <Layout>
        <Header />

        <Main>
          <Section css={{ paddingBottom: "24px" }}>
            <Container
              css={{
                padding: "12px",
              }}
            >
              <div css={{ fontSize: "6px", [mq(1)]: { maxWidth: "50%" } }}>
                <PageTitle>Organisatie</PageTitle>
                <PageSubTitle>Waar we voor staan</PageSubTitle>
              </div>
            </Container>
          </Section>

          <Container>
            <div
              css={{
                padding: "6px",
              }}
            >
              <Section
                css={{
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  [mq(1)]: { maxWidth: "75%" },
                }}
              >
                <Title>Het Bestuur</Title>
                <div>
                  <p>
                    Het bestuur van de Stichting MijnGilde bestaat uit de
                    volgende personen:
                  </p>

                  <p>
                    <ol css={{ margin: 0, padding: 0, listStyle: "none" }}>
                      <li>Mw. G.A.A. Hooghiemstra-Pol voorzitter</li>
                      <li>Dhr. M.S. Dames secretaris</li>
                      <li>Dhr. F.T. Valk penningmeester</li>
                    </ol>
                  </p>

                  <p>
                    <ol css={{ margin: 0, padding: 0, listStyle: "none" }}>
                      <li>RSIN-nummer: 861949237</li>
                      <li>KVK-nummer: 81138121</li>
                      <li>Rekeningnummer: NL85 ABNA 0102046425</li>
                    </ol>
                  </p>

                  <h3 css={{ marginTop: "12px" }}>Bezoldiging bestuurders:</h3>
                  <p>
                    Het bestuur is onbezoldigd en krijgt uitsluitend een
                    vergoeding voor de gemaakte onkosten.
                  </p>
                </div>
              </Section>

              <Section
                css={{
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  [mq(1)]: { maxWidth: "75%" },
                }}
              >
                <Title>Documenten</Title>
                <div>
                  <ul css={{ margin: 0, paddingLeft: 24 }}>
                    <li>
                      <a href={statuten2020Pdf} target="_blank">
                        Statuten
                      </a>
                    </li>
                    <li>
                      <a href={beleidsPlan2021Pdf} target="_blank">
                        Beleidsplan
                      </a>
                    </li>
                  </ul>
                </div>
              </Section>

              <Section
                css={{
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  [mq(1)]: { maxWidth: "75%" },
                }}
              >
                <Title>Financieel</Title>

                <div>
                  <h3>2023:</h3>
                  <ul css={{ margin: 0, paddingLeft: 24 }}>
                    <li>
                      <a href={publicatieStukken2023Pdf} target="_blank">
                        Financieel overzicht 2023
                      </a>
                    </li>
                  </ul>
                </div>

                <div css={{ paddingTop: 12 }}>
                  <h3>2022:</h3>
                  <ul css={{ margin: 0, paddingLeft: 24 }}>
                    <li>
                      <a href={publicatieStukken2022Pdf} target="_blank">
                        Financieel overzicht 2022
                      </a>
                    </li>
                  </ul>
                </div>

                <div css={{ paddingTop: 12 }}>
                  <h3>2021:</h3>
                  <ul css={{ margin: 0, paddingLeft: 24 }}>
                    <li>
                      <a href={publicatieStukken2021Pdf} target="_blank">
                        Financieel overzicht 2021
                      </a>
                    </li>
                  </ul>
                </div>
              </Section>
            </div>
          </Container>
        </Main>

        <Footer />
      </Layout>
    </>
  );
};

export default OrgPage;
